<template>
  <div class="fd-block sm:fd-flex fd-items-center fd-justify-between">
    <b-breadcrumb>
      <b-breadcrumb-item to="/" class="fd-flex fd-items-center fd-justify-center">
        <feather-icon
          icon="HomeIcon"
          size="16"
          class="align-text-top"
        />
      </b-breadcrumb-item>
      <b-breadcrumb-item
        v-for="item in $route.meta.breadcrumbs"
        :key="item.text"
        :active="item.active"
        :to="item.to"
        class="fd-flex fd-items-center fd-justify-between"
      >
        {{ $t(`breadcrumb.${item.text}`) }}
      </b-breadcrumb-item>
    </b-breadcrumb>

    <div v-if="hasBtn">
      <b-button
        variant="primary"
        @click="clickedBtn"
        class="fd-w-full sm:fd-w-auto fd-flex fd-items-center fd-justify-center fd-mt-4 sm:fd-mt-0"
      >
        <div class="fd-inline-flex fd-items-center fd-py-1">
          <feather-icon v-if="btnIcon" :icon="btnIcon" size="12" />
          <span :class="[btnIcon ? 'fd-ml-2' : '']">{{ $t(`breadcrumb.btn.${btnTitle}`) }}</span>
        </div>
      </b-button>
    </div>
  </div>
</template>

<script>
import { BBreadcrumb, BBreadcrumbItem, BButton } from 'bootstrap-vue'
import {bus} from "@/main";

export default {
  name: "BreadcrumbCustom",
  components: {BBreadcrumb, BBreadcrumbItem, BButton},
  data() {
    return {
      hasBtn: '',
      btnTitle: '',
      btnIcon: ''
    }
  },
  created() {
    this.createBtn()
  },
  watch: {
    '$route.name': {
      handler(newVal) {
        this.hasBtn = false
        this.btnTitle = ''
        this.btnIcon = ''
        this.createBtn()
      }
    }
  },
  methods: {
    createBtn() {
      const btnRoute = this.$route.meta.button
      if (btnRoute) {
        this.hasBtn = true
        this.btnTitle = btnRoute.btnTitle
        if (!btnRoute.btnIcon) return
        this.btnIcon = btnRoute.btnIcon
      }
    },
    clickedBtn() {
      bus.$emit(this.btnTitle)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .breadcrumb .breadcrumb-item {
  color: #93A499 !important;
  &:first-child a {
    color: #284A33 !important;
  }
  a {
    color: #93A499 !important;
  }
}
</style>