<template>
  <div>
    <BreadcrumbCustom />
    <div class="fd-w-full fd-bg-white fd-rounded-lg fd-px-4 fd-py-6 mb-3" v-if="appointment">
      <!-- Header Section -->
      <div class="fd-w-full fd-flex fd-items-center justify-content-between">
        <span class="fd-text-lg fd-font-semibold fd-text-theme-2">
          Details View
        </span>
        <div>
          <b-button class="fd-mr-3" :variant="'primary'" @click="isAppointmentHandlerSidebarActive = true">
            <div class="fd-flex fd-items-center">
              <feather-icon icon="Edit3Icon" size="16" />
              <span class="fd-block fd-ml-3 fd-text-sm fd-font-medium">Edit</span>
            </div>

          </b-button>
          <b-button :variant="'outline-danger'" @click="$root.$emit('bv::show::modal', deleteAppointmentDialogId)">
            <div class="fd-flex fd-items-center">
              <feather-icon icon="Trash2Icon" size="16" />
              <span class="fd-block fd-ml-3 fd-text-sm fd-font-medium">Delete</span>
            </div>

          </b-button>
        </div>
      </div>

      <!-- Body Section -->
      <div class="fd-grid fd-grid-cols-2 fd-gap-6 mt-2">





        <!-- Left Col Section -->
        <div>

          <!-- Internal Agent Card -->
          <div class="card">
            <div class="card-header fd-text-lg fd-font-semibold fd-text-theme-2 border-bottom">
              Internal Agent
            </div>
            <div class="card-body py-2">
              <div class="border-2 p-2  fd-rounded-2xl">
                <div class="fd-grid fd-grid-cols-2 fd-gap-6 ">
                  <b-img width="200" :src="appointment.internal_agent.avatar" fluid alt="Fluid image" />
                  <div class="fd-flex flex-column justify-content-between">
                    <div class="fd-flex fd-items-center justify-content-start ">
                      <SvgLoader :name="'appointment_user'" class="mr-1" />
                      <div class="fd-flex flex-column justify-content-between">
                        <p class="mb-0 fd-text-theme-2 fd-capitalize">{{ appointment.internal_agent.name }}</p>
                        <p class="mb-0  fd-text-theme-3">Name</p>
                      </div>
                    </div>
                    <div class="fd-flex fd-items-center justify-content-start ">
                      <SvgLoader :name="'appointment_call'" class="mr-1" />
                      <div class="fd-flex flex-column justify-content-between">
                        <p class="mb-0 fd-text-theme-2">{{ appointment.internal_agent.phone }}</p>
                        <p class="mb-0  fd-text-theme-3">Phone</p>
                      </div>
                    </div>
                    <div class="fd-flex fd-items-center justify-content-start ">
                      <SvgLoader :name="'appointment_email'" class="mr-1" />
                      <div class="fd-flex flex-column justify-content-between">
                        <p class="mb-0 fd-text-theme-2">{{ appointment.internal_agent.email }}</p>
                        <p class="mb-0  fd-text-theme-3">Email</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <!-- Date & Time Card -->
          <div class="card">
            <div class="card-header fd-text-lg fd-font-semibold fd-text-theme-2 border-bottom">
              Date & Time
            </div>
            <div class="card-body py-2">
              <div class="border-2 p-2  fd-rounded-2xl">
                <div class="fd-grid fd-grid-cols-2 fd-gap-6 ">
                  <div class="fd-flex fd-items-center justify-content-start ">
                    <SvgLoader :name="'appointment_calendar'" class="mr-1" />
                    <div class="fd-flex flex-column justify-content-between">
                      <p class="mb-0 fd-text-theme-2">{{ appointment.date_time.date }}</p>
                      <p class="mb-0  fd-text-theme-3">Date</p>
                    </div>
                  </div>
                  <div class="fd-flex fd-items-center justify-content-start ">
                    <SvgLoader :name="'appointment_clock'" class="mr-1" />
                    <div class="fd-flex flex-column justify-content-between">
                      <p class="mb-0 fd-text-theme-2">{{ appointment.date_time.start_time.substr(0, 5) }}</p>
                      <p class="mb-0  fd-text-theme-3">Time</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>


          <!-- Note Card -->
          <div class="card">
            <div class="card-header fd-text-lg fd-font-semibold fd-text-theme-2 border-bottom">
              Note
            </div>
            <div class="card-body py-2">
              <div class="border-2 p-2  fd-rounded-2xl">
                description
              </div>

            </div>
          </div>

          <!-- Lead Information Card -->
          <div class="card">
            <div class="card-header fd-text-lg fd-font-semibold fd-text-theme-2 border-bottom">
              Lead Information
            </div>
            <div class="card-body py-2">
              <div class="border-2 p-2  fd-rounded-2xl">
                <div class="fd-flex fd-items-center justify-content-start mb-1">
                  <SvgLoader :name="'appointment_user'" class="mr-1" />
                  <div class="fd-flex flex-column justify-content-between">
                    <p class="mb-0 fd-text-theme-2">{{ appointment.lead.name }}</p>
                    <p class="mb-0  fd-text-theme-3">Name</p>
                  </div>
                </div>
                <div class="fd-flex fd-items-center justify-content-start mb-1">
                  <SvgLoader :name="'appointment_personalcard'" class="mr-1" />
                  <div class="fd-flex flex-column justify-content-between">
                    <p class="mb-0 fd-text-theme-2">{{ appointment.lead.ID }}</p>
                    <p class="mb-0  fd-text-theme-3">Passport ID</p>
                  </div>
                </div>
                <div class="fd-flex fd-items-center justify-content-start mb-1">
                  <SvgLoader :name="'appointment_email'" class="mr-1" />
                  <div class="fd-flex flex-column justify-content-between">
                    <p class="mb-0 fd-text-theme-2">{{ appointment.lead.email }}</p>
                    <p class="mb-0  fd-text-theme-3">Email</p>
                  </div>
                </div>
                <div class="fd-flex fd-items-center justify-content-start">
                  <SvgLoader :name="'appointment_template'" class="mr-1" />
                  <div class="fd-flex flex-column justify-content-between">
                    <p class="mb-0 fd-text-theme-2">{{ appointment.lead.template }}</p>
                    <p class="mb-0  fd-text-theme-3">Template</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>



        <!-- Right Col Section -->
        <div>

          <!-- External Agent Card -->
          <div class="card">
            <div class="card-header fd-text-lg fd-font-semibold fd-text-theme-2 border-bottom">
              External Agent
            </div>
            <div class="card-body py-2">

              <!-- Agent Details -->
              <div class="border-2 p-2  fd-rounded-2xl mb-2">
                <div class="fd-grid fd-grid-cols-2 fd-gap-6 ">
                  <b-img width="200" :src="appointment.rs.agent.avatar" fluid alt="Fluid image" />
                  <div class="fd-flex flex-column justify-content-between">
                    <div class="fd-flex fd-items-center justify-content-start ">
                      <SvgLoader :name="'appointment_user'" class="mr-1" />
                      <div class="fd-flex flex-column justify-content-between">
                        <p class="mb-0 fd-text-theme-2">{{ appointment.rs.agent.name }}</p>
                        <p class="mb-0  fd-text-theme-3">Name</p>
                      </div>
                    </div>
                    <div class="fd-flex fd-items-center justify-content-start ">
                      <SvgLoader :name="'appointment_call'" class="mr-1" />
                      <div class="fd-flex flex-column justify-content-between">
                        <p class="mb-0 fd-text-theme-2">{{ appointment.rs.agent.phone }}</p>
                        <p class="mb-0  fd-text-theme-3">Phone</p>
                      </div>
                    </div>
                    <div class="fd-flex fd-items-center justify-content-start ">
                      <SvgLoader :name="'appointment_email'" class="mr-1" />
                      <div class="fd-flex flex-column justify-content-between">
                        <p class="mb-0 fd-text-theme-2">{{ appointment.rs.agent.email }}</p>
                        <p class="mb-0  fd-text-theme-3">Email</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Company Details -->
              <div class="border-2 p-2  fd-rounded-2xl">
                <div class="fd-grid fd-grid-cols-2 fd-gap-6 fd-flex flex-column align-items-center">
                  <b-img width="200" :src="appointment.rs.logo" fluid alt="Fluid image" />
                  <div class="fd-flex flex-column justify-content-between">
                    <div class="fd-flex fd-items-center justify-content-start mb-1">
                      <SvgLoader :name="'appointment_user'" class="mr-1" />
                      <div class="fd-flex flex-column justify-content-between">
                        <p class="mb-0 fd-text-theme-2">{{ appointment.rs.name }}</p>
                        <p class="mb-0  fd-text-theme-3">Name</p>
                      </div>
                    </div>
                    <div class="fd-flex fd-items-center justify-content-start mb-1">
                      <SvgLoader :name="'appointment_document'" class="mr-1" />
                      <div class="fd-flex flex-column justify-content-between">
                        <p class="mb-0 fd-text-theme-2">{{ appointment.rs.ID }}</p>
                        <p class="mb-0  fd-text-theme-3">Business ID</p>
                      </div>
                    </div>
                    <div class="fd-flex fd-items-center justify-content-start mb-1">
                      <SvgLoader :name="'appointment_call'" class="mr-1" />
                      <div class="fd-flex flex-column justify-content-between">
                        <p class="mb-0 fd-text-theme-2">{{ appointment.rs.phone }}</p>
                        <p class="mb-0  fd-text-theme-3">Phone</p>
                      </div>
                    </div>
                    <div class="fd-flex fd-items-center justify-content-start ">
                      <SvgLoader :name="'appointment_email'" class="mr-1" />
                      <div class="fd-flex flex-column justify-content-between">
                        <p class="mb-0 fd-text-theme-2">{{ appointment.rs.email }}</p>
                        <p class="mb-0  fd-text-theme-3">Email</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <!-- Required Forms Card -->
          <div class="card">
            <div class="card-header fd-text-lg fd-font-semibold fd-text-theme-2 border-bottom">
              Required Forms
            </div>
            <div class="card-body py-2">
              <div class="border-2 p-2  fd-rounded-2xl">
                <div class="fd-grid fd-grid-cols-4 fd-gap-6 ">
                  <a class="card border rounded-lg mb-1" v-for="form in appointment.required_forms"
                    :key="'form' + form.id" :href="form.link" target="_blank">
                    <div class="w-100 h-100 fd-flex align-items-center justify-content-center fd-bg-theme-1"
                      style="min-height:8rem ;">
                      <b-img class="fd-rounded-t-lg " width="50" :src="require('@/assets/images/icons/pdf.png')" fluid
                        alt="Fluid image" />
                    </div>
                    <div class="card-body py-50 px-50">
                      <div class="fd-flex align-items-center justify-content-between">
                        <p class="mb-0 fd-text-theme-2">{{ form.name }}</p>
                        <p class="mb-0  fd-text-theme-3">{{ form.size }}</p>
                      </div>
                    </div>
                  </a>



                </div>
              </div>

            </div>
          </div>

        </div>



      </div>

      <ConfirmDialogue :id="deleteAppointmentDialogId" @remove="removeAppointment" />

      <AppointmentEventHandler :appointmentId="$route.params.id" v-model="isAppointmentHandlerSidebarActive"
        @update-event="getAppointment" />
    </div>


  </div>
</template>

<script>
import BreadcrumbCustom from "@/views/components/global/BreadcrumbCustom";
import { BButton, BImg } from "bootstrap-vue";
import AppointmentService from '@/http/appointment/appointmentService';
import ConfirmDialogue from "@/views/components/global/ConfirmDialogue";
import AppointmentEventHandler from "@/views/components/appointmentManagement/AppointmentEventHandler.vue";



export default {
  components: {
    BreadcrumbCustom, BButton, BImg, ConfirmDialogue, AppointmentEventHandler
  },
  data() {
    return {
      appointment: null,
      deleteAppointmentDialogId: 'deleteAppointmentConfirmDialogue',
      isAppointmentHandlerSidebarActive: false

    }
  },
  mounted() {
    this.getAppointment()
  },
  methods: {
    getAppointment() {
      AppointmentService.getAppointmentDetails(this.$route.params.id)
        .then(response => this.appointment = response.data.result)
    },
    removeAppointment() {
      this.$root.$emit("bv::hide::modal", this.deleteAppointmentDialogId);
      AppointmentService.deleteAppointment(this.appointment.id).then((response) => {
        this.$router.push({ name: 'appointment.tableView' })
      }).catch(err => {
      });


    }
  }
};
</script>

<style>
</style>