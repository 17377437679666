<template>
  <b-button @click="$emit('click')" :disabled="loading" :variant="variant">
    <div class="d-flex justify-content-center align-items-center">
      <b-spinner
        style="width: 1.2rem; height: 1.2rem; border-width: 0.15em"
        small
        v-show="loading"
        variant="light"
      ></b-spinner>

      <span v-show="!loading">
        {{ text }}
      </span>
    </div>
  </b-button>
</template>

<script>
import { BButton, BSpinner } from "bootstrap-vue";

export default {
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
    text: {
      default: "Submit",
      type: String,
    },
    variant: {
      default: "primary",
      type: String,
    },
  },
  components: {
    BSpinner,
    BButton,
  },
};
</script>

<style>
</style>