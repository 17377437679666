<template>
  <div v-if="Object.keys(appointmentOptions).length">
    <b-sidebar
      id="appointmentEventHandlerSidebar"
      :visible="isAppointmentHandlerSidebarActive"
      no-header
      bg-variant="white"
      shadow
      right
      lazy
      backdrop
      :backdrop-variant="'dark'"
      width="55%"
      @change="
        (val) => $emit('update:is-appointment-handler-sidebar-active', val)
      "
    >
      <div
        class="fd-relative fd-flex fd-items-center fd-justify-between fd-p-3"
        style="background-color: #e9edeb"
      >
        <div class="fd-flex fd-items-center">
          <span class="fd-block fd-ml-2"
            >{{ appointment ? "Edit" : "Create" }} Appointment</span
          >
        </div>
        <div
          @click="$emit('update:is-appointment-handler-sidebar-active', false)"
          class="fd-cursor-pointer"
        >
          <SvgLoader :name="'close'" />
        </div>
      </div>

      <div>
        <div class="fd-p-4 fd-pb-32">
          <div class="fd-text-base fd-text-theme-13 fd-mb-4">
            <div class="fd-grid fd-grid-cols-2 fd-gap-6">
              <div class="fd-px-3 fd-py-3 fd-rounded fd-bg-theme-1">
                <div class="fd-font-semibold fd-mb-5">Internal Agent</div>
                <div class="fd-mb-3">
                  <label for="internal_agent_language" class="fd-mb-2"
                  >Language</label
                  >
                  <multiselect
                    id="internal_agent_language"
                    label="name"
                    track-by="code"
                    select-label=""
                    deselect-label=""
                    v-model="inputData.internal_agent.language"
                    :options="appointmentOptions.internal.languages"
                  />
                </div>
                <div class="fd-mb-3">
                  <label for="internal_agent" class="fd-mb-2">Agent Name</label>
                  <multiselect
                    id="internal_agent"
                    select-label=""
                    deselect-label=""
                    track-by="id"
                    v-model="inputData.internal_agent.agent"
                    :options="appointmentOptions.internal.agents"
                    :custom-label="firstNameWithLastName"
                    @input="getAgentBlockTimes"
                  />
                </div>
                <div class="fd-mb-3">
                  <label for="internal_agent_language" class="fd-mb-2"
                  >Project</label
                  >
                  <multiselect
                    id="internal_agent_project"
                    label="name"
                    track-by="id"
                    select-label=""
                    deselect-label=""
                    v-model="inputData.internal_agent.project"
                    :options="appointmentOptions.internal.projects"
                  />
                </div>
              </div>
              <div class="fd-px-3 fd-py-3 fd-pb-1 fd-rounded fd-bg-theme-1">
                <div class="fd-font-semibold fd-mb-5">External Agent</div>
                <div class="fd-mb-3">
                  <label for="external_rs_agent" class="fd-mb-2">RS Agent Name</label>
                  <multiselect
                    id="external_rs_agent"
                    label="name"
                    track-by="id"
                    select-label=""
                    deselect-label=""
                    @input="selectedRsAgent"
                    v-model="inputData.external_agent.rsAgent"
                    :options="appointmentOptions.external"
                  />
                </div>
                <div class="fd-mb-3">
                  <label for="external_agent" class="fd-mb-2">Agent Name</label>
                  <multiselect
                    id="external_agent"
                    track-by="id"
                    select-label=""
                    deselect-label=""
                    :custom-label="firstNameWithLastName"
                    v-model="inputData.external_agent.agent"
                    :options="getRsAgents"
                  />
                </div>
                <div class="fd-mb-1 fd-flex fd-justify-between fd-mt-10">
                  <label for="direct_to_lead" class="fd-mb-2"
                    >Direct contract to lead</label
                  >
                  <b-form-checkbox id="direct_to_lead" class="direct_to_lead" v-model="inputData.direction_contract" />
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              fd-text-base
              fd-text-theme-13
              fd-mb-4
              fd-px-3
              fd-py-3
              fd-rounded
              fd-bg-theme-1
            "
          >
            <div class="">
              <div class="fd-font-semibold fd-mb-5">Date & Time</div>
              <div class="fd-mb-3">
                <label for="appointment_date" class="fd-mb-2">Date</label>
                <flat-pickr
                  id="appointment_date"
                  v-model="inputData.date_time.date"
                  @on-change="getAgentBlockTimes"
                  class="form-control"
                  :config="{
                    altInput: true,
                    altFormat: 'F j, Y',
                    dateFormat: 'Y-m-d',
                    altInputClass: 'form-control bg-white background--color--changed',
                  }"
                />
              </div>
              <div class="fd-mb-3">
                <label for="appointment_start_time" class="fd-mb-2"
                  >Start Time</label
                >
                <b-form-timepicker
                  v-model="inputData.date_time.start_time"
                  locale="en"
                ></b-form-timepicker>
              </div>
              <div class="fd-mb-3">
                <label for="appointment_end_time" class="fd-mb-2"
                  >End Time</label
                >
                <b-form-timepicker
                  v-model="inputData.date_time.end_time"
                  locale="en"
                ></b-form-timepicker>
              </div>
              <div class="fd-mb-3">
                <label class="fd-mb-2">Block Time</label>
                <div class="w-100 bg-white fd-p-3" id="agent_block_time">
                  <div
                    id="agent_block_time_hours"
                    class="
                      w-100
                      fd-rounded
                      position-relative
                      fd-flex fd-justify-between
                    "
                  >
                    <div
                      class=""
                      v-for="index in maxHour - minHour + 1"
                      :key="index"
                      :style="{ width: hourWidthPercent + '%' }"
                    >
                      <div class="text-white text-center fd-mt-1">
                        <span class="">
                          {{ index - 1 + minHour + ":00" }}
                        </span>
                      </div>
                      <div class="bolck_time_item"></div>
                    </div>
                    <div
                      v-for="(item, index) in agentBlockTimes"
                      :key="'agentBlockTime' + index"
                      class="position-absolute blockTimeDiv"
                      :style="{
                        width: getBlockTimeWidthPercent(item) + '%',
                        left: getBlockTimeLeftPercent(item) + '%',
                      }"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              fd-text-base
              fd-text-theme-13
              fd-mb-4
              fd-px-3
              fd-py-3
              fd-rounded
              fd-bg-theme-1
            "
          >
            <div class="">
              <div class="fd-font-semibold fd-mb-5">Add Note</div>
              <div class="fd-mb-3">
                <label for="note" class="fd-mb-2">Note</label>
                <TextEditor id="note" :value.sync="inputData.note" />
              </div>
            </div>
          </div>
          <div
            class="
              fd-text-base
              fd-text-theme-13
              fd-mb-4
              fd-px-3
              fd-py-3
              fd-rounded
              fd-bg-theme-1
            "
          >
            <div class="">
              <div class="fd-font-semibold fd-mb-5">Lead Information</div>
              <div class="fd-mb-3">
                <label for="lead_name" class="fd-mb-2">Name</label>
                <b-form-input
                  id="lead_name"
                  v-model="inputData.lead.name"
                  type="text"
                  debounce="500"
                  class=""
                ></b-form-input>
              </div>
              <div class="fd-mb-3">
                <label for="lead_email" class="fd-mb-2">Email</label>
                <b-form-input
                  id="lead_email"
                  v-model="inputData.lead.email"
                  type="email"
                  debounce="500"
                  class=""
                ></b-form-input>
              </div>
              <div class="fd-mb-3">
                <label for="lead_phone" class="fd-mb-2">Phone</label>
                <b-form-input
                  id="lead_phone"
                  v-model="inputData.lead.phone"
                  type="tel"
                  debounce="500"
                  class=""
                ></b-form-input>
              </div>
              <div class="fd-mb-3">
                <label for="lead_id" class="fd-mb-2">ID</label>
                <b-form-input
                  id="lead_id"
                  v-model="inputData.lead.id"
                  type="text"
                  debounce="500"
                  class=""
                ></b-form-input>
              </div>
              <div class="fd-mb-3">
                <label for="lead_template" class="fd-mb-2">Template</label>
                <multiselect
                  id="lead_template"
                  select-label=""
                  deselect-label=""
                  label="name"
                  track-by="id"
                  v-model="inputData.lead.template"
                  :options="appointmentOptions.templates"
                />
              </div>
              <div class="fd-mb-3">
                <label for="lead_price" class="fd-mb-2">Price</label>
                <vue-slider
                  id="lead_price"
                  class=""
                  v-model="inputData.lead.price"
                  :process-style="{ backgroundColor: '#284A33', height: '5px' }"
                  v-bind="sliderOptions"
                  :dotStyle="{ width: '18px', height: '18px' }"
                  :tooltip="'none'"
                  :use-keyboard="false"
                  :lazy="true"
                >
                  <template v-slot:dot="{}">
                    <div :class="['custom-dot']"></div>
                  </template>
                </vue-slider>
                <div class="fd-mt-2">
                  <span class="fd-text-xs" style="color: #626687">{{
                    inputData.lead.price[0] + " - " + inputData.lead.price[1]
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="fd-w-full fd-absolute fd-left-0 fd-bottom-0 fd-p-3 fd-bg-white"
        style="z-index: 99"
      >
        <SubmitButton
          @click="submit()"
          variant="primary"
          class="fd-w-full fd-mb-2"
          :text="appointment ? 'Update Invitation' : 'Send Invite'"
          :loading="loading"
        />
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormTimepicker,
  BSidebar,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ModelListSelect } from "vue-search-select";
import "vue-search-select/dist/VueSearchSelect.css";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import { mapGetters } from "vuex";
import flatPickr from "vue-flatpickr-component";
import TextEditor from "@/views/components/global/TextEditor";
import SubmitButton from "@/views/components/global/SubmitButton";
import AppointmentService from "@/http/appointment/appointmentService";

export default {
  name: "AppointmentEventHandler",
  props: {
    appointmentId: {
      default: null,
    },
    isAppointmentHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      default: null,
    },
  },
  model: {
    prop: "isAppointmentHandlerSidebarActive",
    event: "update:is-appointment-handler-sidebar-active",
  },

  components: {
    BFormInput,
    ModelListSelect,
    VueSlider,
    BFormTimepicker,
    flatPickr,
    BSidebar,
    BButton,
    BFormCheckbox,
    TextEditor,
    SubmitButton,
  },
  data() {
    return {
      appointment: null,
      sliderOptions: {
        minRange: 1,
        min: 1,
        max: 500000,
        interval: 1,
      },
      inputData: {
        direction_contract: true,
        internal_agent: {
          agent: null,
          language: null,
          projects: null,
        },
        external_agent: {
          rsAgent: null,
          agent: null,
          language: null,
          direct_to_lead: false,
        },
        date_time: {
          date: null,
          start_time: null,
          end_time: null,
        },
        note: null,
        lead: {
          id: null,
          name: null,
          email: null,
          phone: null,
          template: null,
          price: [100000, 400000],
        },
      },
      loading: false,
      maxHour: 18,
      minHour: 8,
      rsAgentsList: []
    };
  },
  created() {
    this.$store.dispatch("tableView/getAppointmentOptions");
  },
  watch: {
    appointmentId: {
      handler(newVal) {
        this.appointment = null;
        this.resetInputData();
        if (newVal !== null) {
          AppointmentService.getAppointmentDetails(this.appointmentId).then(
            (response) => {
              this.appointment = response.data.result;
              this.resetInputData();
            }
          );
        }
      },
      deep: true,
      immediate: true,
    },
    event: {
      handler(newVal) {

        if (newVal && newVal.start && !this.appointmentId) {
          let dateTime = new  Date(newVal.start);
          this.inputData.date_time.date = dateTime.getUTCFullYear()+"-"+(dateTime.getUTCMonth()+1)+"-"+(dateTime.getDate());
          this.inputData.date_time.start_time = dateTime.getHours()+":"+dateTime.getMinutes();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      appointmentOptions: "tableView/appointmentOptions",
      agents: "tableView/agents",
      mappedInternalAgents: "tableView/mappedInternalAgents",
    }),
    agentBlockTimes() {
      let agentBlockTimes = this.$store.getters['tableView/agentBlockTimes']
      if (!agentBlockTimes.length) return []
      return agentBlockTimes.map(item => {
        item.start_datetime = item.start_datetime.split(' ')[1]
        item.end_datetime = item.end_datetime.split(' ')[1]
        return item
      })
    },
    hourWidthPercent() {
      let hoursCount = this.maxHour - this.minHour + 1;

      return 100 / hoursCount;
    },
    getRsAgents() {
      return this.rsAgentsList
    },
  },
  methods: {
    selectedRsAgent(val, id) {
      if (!val) {
        this.rsAgentsList = []
        return
      }
      this.appointmentOptions.external.forEach(item => {
        if (item.id === val.id) {
          this.rsAgentsList = item.agents
        }
      })
    },
    resetInputData() {
      if (this.appointment) {
        this.inputData = {
          internal_agent: {
            id: this.appointment.internal_agent.id,
            language: "en",
          },
          external_agent: {
            id: this.appointment.rs.agent.id,
            language: null,
            direct_to_lead: false,
          },
          date_time: {
            date:  this.appointment.date_time.date,
            start_time: this.appointment.date_time.start_time.substr(0,5),
            end_time: this.appointment.date_time.end_time.substr(0,5),
          },
          note: null,
          lead: {
            id: this.appointment.lead.id,
            name: this.appointment.lead.name,
            email: this.appointment.lead.email,
            phone: this.appointment.lead.phone,
            template: this.appointment.lead.template,
            price: [100000, 400000],
          },
        };
      } else {
        this.inputData = {
          internal_agent: {
            id: null,
            language: null,
          },
          external_agent: {
            id: null,
            language: null,
            direct_to_lead: false,
          },
          date_time: {
            date: null,
            start_time: null,
            end_time: null,
          },
          note: null,
          lead: {
            id: null,
            name: null,
            email: null,
            phone: null,
            template: null,
            price: [100000, 400000],
          },
        };
      }
    },
    getAgentBlockTimes() {
      let date = this.inputData.date_time.date;
      let agentId = this.inputData.internal_agent.agent.id;
      if (date && agentId) {
        this.$store.dispatch("tableView/getAgentBlockTimes", {
          date: date,
          id: agentId,
        });
      } else {
        this.$store.commit("tableView/setAgentBlockTimes", []);
      }
    },
    clearAllFilters() {},

    timeToDecimal(t) {
      var arr = t.split(":");
      var dec = parseInt((arr[1] / 6) * 10, 10);

      return parseFloat(
        parseInt(arr[0], 10) + "." + (dec < 10 ? "0" : "") + dec
      );
    },
    getBlockTimeWidthPercent(item) {
      return (
        (this.timeToDecimal(item.end_datetime) - this.timeToDecimal(item.start_datetime)) *
        this.hourWidthPercent
      );
    },
    getBlockTimeLeftPercent(item) {
      return (
        (this.timeToDecimal(item.start_datetime) - this.minHour) *
          this.hourWidthPercent +
        this.hourWidthPercent / 2
      );
    },
    firstNameWithLastName ({ first_name, last_name }) {
      return `${first_name} ${last_name}`
    },
    makeToast(message, isDanger = false) {
      this.$bvToast.toast(message, {
        title: isDanger ? "Error" : "Done",
        toaster: "b-toaster-top-right",
        variant: isDanger ? "danger" : "success",
        solid: true,
      });
    },
    async submit() {
      this.loading = true;
      if (this.appointment) await this.updateAppointment();
      else await this.createAppointment();
      this.loading = false;
    },
    async createAppointment() {
      await this.$store
        .dispatch("tableView/createNewAppointment", {
          ana_agent_id: this.inputData.internal_agent.agent.id,
          rs_agent_id: this.inputData.external_agent.agent.id,
          note: this.inputData.note,
          lead: {
            name: this.inputData.lead.name,
            email: this.inputData.lead.email,
            phone: this.inputData.lead.phone,
            identifier: this.inputData.lead.id,
            template_type_id: this.inputData.lead.template.id,
            min_price: this.inputData.lead.price[0],
            max_price: this.inputData.lead.price[1]
          },
          start_datetime: this.inputData.date_time.start_time,
          end_datetime: this.inputData.date_time.end_time,
          direction_contract: this.inputData.direction_contract
        })
        .then((response) => {
          this.appointment = response.data.result;
          this.makeToast("Appointment created successfully");
          this.$emit("add-event", this.generateCalendarItem());
          this.$emit("update:is-appointment-handler-sidebar-active", false);
        })
        .catch((err) => {});
    },
    async updateAppointment() {
      await this.$store
        .dispatch("tableView/updateAppointment", {
          data: this.inputData,
          id: this.appointment.id,
        })
        .then((response) => {
          this.appointment = response.data.result;
          this.makeToast("Appointment updated successfully");
          this.$emit("update-event", this.generateCalendarItem());
          this.$emit("update:is-appointment-handler-sidebar-active", false);
        })
        .catch((err) => {});
    },
    generateCalendarItem() {
      return {
        id: this.appointment.id,
        allDay: false,
        title: this.appointment.internal_agent.name,
        start:
          this.appointment.date_time.date +
          "T" +
          this.appointment.date_time.start_time,
        end:
          this.appointment.date_time.date +
          "T" +
          this.appointment.date_time.end_time,
        extendedProps: {
          calendar: this.appointment.internal_agent.id,
          guests: [],
          location: "",
          description: "",
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.custom-dot {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: #e1cdae;
  transition: all 0.3s;
  position: absolute;
  top: -2px;
  cursor: pointer;

  &:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
  }

  &:hover {
    transform: rotateZ(45deg);
  }
}

::v-deep .vue-slider-rail {
  background-color: #ededed !important;
}
.direct_to_lead {
  padding-left: 1.1rem !important;
}

#agent_block_time {
  height: 10rem;
  #agent_block_time_hours {
    background: $primary;
    height: 2rem;
    .bolck_time_item {
      border-right: 1px solid #e9edeb;
      height: 6.5rem;
      margin-top: 0.4rem;
      width: 50%;
    }
  }
}

.blockTimeDiv {
  background: #bec9c2;
  border: 1px solid transparent;
  border-radius: 6px;
  height: 3rem;
  top: 4rem;
}

::v-deep .background--color--changed.form-control:disabled, ::v-deep .background--color--changed.form-control[readonly] {
  background-color: #ffffff !important;
}

</style>
